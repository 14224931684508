import { Link } from 'gatsby';
import $ from 'jquery'; 
import OwlCarousel from 'react-owl-carousel3';
import React, {useState, useRef} from 'react'

import { connect } from 'react-redux'

import { getFavoritesAction,clearFavoritesAction, deleteFavoriteAction,changeSelectedAction } from '../redux/propertiesDucks'

//Helpers
import { getCover,getPreviewSlider, getQuality } from '../helpers/helper.rendering';
import { addFavorites, getfavorites, checkFavorite } from '../helpers/helper.favorites';
import { 
    getOperations,
    getType,
    getEnvironment,
    getSurface,
    getLocation,
    getFakeAddres,
    getPrices,
    makeLink,
    } from '../helpers/helper.properties';

import toast, { Toaster } from 'react-hot-toast';
import { graphql, useStaticQuery } from 'gatsby';

const Card = (props) => {

    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
          }
    }`)

    const { property } = props
    const { settings } = props
    const { dispatch } = props
    const opacity = props?.opacity

    const [listFavorites,setListFavorites] = useState(getfavorites("prop",settings.short_name))
    const slider = useRef()

    const toastCheck = (id,fake_address) => {
        if(!checkFavorite(id,"prop",settings.short_name))
            return toast.success(fake_address + " agregada a tus favoritos") 
        else
            return toast.success(fake_address + " eliminada de tus favoritos")
    }

    const addHoverMap = (property) => {
        // $('.marker-' + id).addClass(' hover-marker');
        $('#marker-' + property.id).addClass(' hover-marker');
        $('#marker-' + property.development?.id).addClass(' hover-marker');
    }
    const removeHoverMap = (id) => {
        // $('.marker-' + id).removeClass(' hover-marker');
        $('#marker-' + property.id).removeClass(' hover-marker');
        $('#marker-' + property.development?.id).removeClass(' hover-marker');
    }

    return property && (
        <div 
            onMouseEnter={() => addHoverMap(property)}
            onMouseLeave={() => removeHoverMap(property)} 
            id={"card-grid-"+ (props.grid_map && property.id)}
            class={"card prop-card " + (props.inMap ? 'map-card' : '') + (opacity ? 'opacity-card' : '') + (props.grid_map ? ' grid-card  ' : '')}>
            <div class={"card-image " + (props.grid_map && "grid_map_image")}>
                {props.basic || props.inMap || props.grid_map  ? 
                    props.inMap ? 
                        <a target="_blank" href={makeLink(property)} >
                            <img src={getQuality(getCover(property.photos)) } className='notScale' alt={realEstate?.name} />
                        </a>
                        :
                        <Link target={props.inMap ? "__blank" : ''} to={makeLink(property)} >
                            <img src={getQuality(getCover(property.photos)) } className={'notScale '} alt={realEstate?.name} />
                        </Link>
                :
                    <>
                        <OwlCarousel ref={slider}
                            items={1}
                            margin={0}
                            loop={true}
                            className="overflow-hidden card-carousel">
                                {(getPreviewSlider(property.photos)).map((photo, index) => (
                                    <Link target={props.inMap ? "__blank" : ''} to={makeLink(property)} >
                                        {<img src={index === 0 ?  getQuality(photo) : getQuality(photo)} className='notScale' alt={realEstate?.name} />}
                                    </Link>   
                                ))}
                        </OwlCarousel>
                        <div className={"arrows " + (getPreviewSlider(property.photos).length > 1 ? '' : 'd-none')}>
                            <div className="prev cursor-pointer" onClick={() => slider.current.prev()}>{"<"}</div>
                            <div className="next cursor-pointer" onClick={() => slider.current.next()}>{">"}</div>
                        </div>
                    </>
                }
                {
                    property && 
                        <div  class="badges">
                            {getOperations(property).map((operation, index) => (
                                <div key={index} className={"badge " + operation.toLowerCase()}>{operation}</div>
                            ))}
                        </div>
                }
                <div onClick={() => dispatch(changeSelectedAction(0))} className="close-card"><i></i><i></i></div>
                <span onClick={() => toastCheck(property.id, property.fake_address) + addFavorites(property.id,"prop",settings.short_name) + setListFavorites(getfavorites("prop",settings.short_name)) + dispatch(deleteFavoriteAction(property.id))} className={"d-lg-inline-flex icon-solid-like btn fav " +  (listFavorites.find(element => element.toString() === property.id.toString()) ? 'active' : '')}  style={{zIndex:"9999"}}>
                </span>
            </div>
            <div class="card-body">
                <div class={"content-extra row d-flex justify-content-between align-items-start "+ (props.grid_map && 'd-none')}>
                    <div class={"col-12 col-lg-6 mb-3 extra-data "}>
                        <span>{getType(property)} ·  {getEnvironment(property)} ambiente{getEnvironment(property) > 1 ? 's' : ''} · {getSurface(property)}</span>
                    </div>
                    <div class={"col-12 col-lg-6 mb-2 text-lg-end location " }>
                        <span>
                            <i className='icon-location-solid me-2'></i>
                            {getLocation(property)}
                        </span>
                    </div>
                </div>
                <div class="content-extra">
                    <h3 class="card-title">
                        {
                            props.inMap ?
                                <a target={"__blank"} href={makeLink(property)}>{getFakeAddres(property)}</a>
                            :
                                <Link target={props.inMap ? "__blank" : ''} to={makeLink(property)}>{getFakeAddres(property)}</Link>
                        }
                    </h3>  
                    <div class={"col-12 mb-2 text-lg-start location " + (!props.grid_map && 'd-none')}>
                        <span>
                            {getLocation(property)}
                        </span>
                        <hr />
                    </div>
                    <div class={"col-12 mb-3 extra-data " + (!props.grid_map && 'd-none')}>
                        <span>{getType(property)} ·  {getEnvironment(property)} ambiente{getEnvironment(property) > 1 ? 's' : ''} · {getSurface(property)}</span>
                    </div>
                    {getPrices(property).map((price, index) => (
                        <p class={"prop-price " + price.type }>{price.currency} {price.price > 0 ? Intl.NumberFormat("de-DE").format(price.price) : price.price}</p>
                    ))} 
                </div>
            </div>
        </div>

    )
}

export default connect(state => ({
    settings: state.settings,
}),null)(Card);