import { Link } from "gatsby"
import React from "react"
import { graphql, useStaticQuery } from 'gatsby';
import VisibilitySensor from 'react-visibility-sensor';
import CountUp from 'react-countup';


const About = () => {
    const { realEstate } = useStaticQuery(graphql`
            query {
                realEstate {
                    sections {
                        home {
                          about {
                            title
                            paragraph
                            image
                            buttons {
                              link
                              value
                            }
                            items {
                              paragraph
                              value
                            }
                          }
                        }
                        about {
                            image
                        }
                    }
                }
    }`)

    // Difference between years for animation
    const currentYear = new Date().getFullYear();
    const initialYear = 2011;

    return realEstate.sections && (
        <section class="about-us py-5 mt-5">
            {/* <section class="about-us py-5 mt-5" style={{backgroundImage:"url(" + realEstate.sections.about.image + ""}}> */}
            <div class="opacity"></div>
            <div class="container-fluid py-5">
                <div class="row">
                    <div class="col-lg-2">
                        <p class="section-name">{realEstate.sections.home.about.title}</p>
                    </div>
                    <div class="col-lg-6 pe-lg-5">
                        <h2>{realEstate.sections.home.about.paragraph}</h2>
                        {/* <h2>SOMOS UN EQUIPO PROFESIONAL, MULTIDISCIPLINARIO,<br class="d-none d-lg-block" /> EMPÁTICO Y RESOLUTIVO EN EL MUNDO DEL REAL ESTATE.</h2> */}
                    </div>
                    {realEstate.sections.home.about.items.length > 1 &&
                        <>
                            <div class="ps-lg-5 col-lg-3 contador border-start border-3 mt-5 mt-lg-0">
                                <div class="item">
                                    
                                    <div class="">
                                        <h1 class="hyper d-flex justify-content-center">+
                                            <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                                                {({ isVisible }) => (
                                                <span class="counter-value one">
                                                    {isVisible ? <CountUp end={currentYear - initialYear} /> : null}
                                                </span>
                                                )}
                                            </VisibilitySensor>
                                        </h1>
                                    </div>
                                    <div class="ps-lg-5 text-center text-lg-start">
                                        <p>{realEstate.sections.home.about.items[0].paragraph}</p>
                                        {/* <p>AÑOS DE EXPERIENCIA <br class="d-none d-lg-block" />EN EL MERCADO <br class="d-none d-lg-block" />INMOBILIARIO.</p> */}
                                    </div>
                                   
                                </div>
                            </div>
                            {/* <div class="col-lg-7">
                                <div class="item" id="counter">
                                    <div class="row no-gutters">
                                        <div class="col-7 text-end">
                                            <h1 class="hyper d-flex justify-content-end">+
                                                <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                                                    {({ isVisible }) => (
                                                    <span class="counter-value one">
                                                        {isVisible ? <CountUp end={realEstate.sections.home.about.items[1].value} /> : null}
                                                    </span>
                                                    )}
                                                </VisibilitySensor>
                                            </h1>
                                        </div>
                                        <div class="col-5 px-3">
                                            <p>{realEstate.sections.home.about.items[1].paragraph}</p>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </>
                           
                        
                    }
                    <div class="col-12 mt-5 offset-lg-2 text-center text-lg-start">
                        <Link to={realEstate.sections.home.about.buttons[0].link} class="btn btn-secondary">{realEstate.sections.home.about.buttons[0].value}</Link>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default About